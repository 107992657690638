import { useUser } from "hooks/useUser";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useQuery } from "urql";
import { userQuery } from "utils/queries/user";

/**
 * Account info panel
 * Summary info  for the current user
 */
const AccountInfoPanel = () => {
  // Context
  const { user } = useUser();

  // Query
  const variables = { customerAccessToken: user!.accessToken };
  const [{ data }] = useQuery({
    query: userQuery,
    variables,
    requestPolicy: "cache-only"
  });

  if (data && data.customer) {
    return (
      <Container>
        <Title>
          <FormattedMessage id="label.customerInformation" />
        </Title>
        <Grid>
          <Item>
            <Subject>
              <FormattedMessage id="label.name" />:
            </Subject>
            <Content>
              {data.customer.firstName} {data.customer.lastName}
            </Content>
          </Item>
          <Item>
            <Subject>
              <FormattedMessage id="label.email" />:
            </Subject>
            <Content>{data.customer.email}</Content>
          </Item>
          <Item>
            <Subject>
              <FormattedMessage id="label.newsletter" />:
            </Subject>
            <Content>
              {data.customer.acceptsMarketing ? "Subscribed" : "Not subscribed"}
            </Content>
          </Item>
        </Grid>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #333;
  letter-spacing: 0.06em;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Subject = styled.div`
  width: 140px;
  flex: 0 0 140px;
  font-weight: 600;
  @media (max-width: 500px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const Content = styled.div`
  flex-grow: 1;
`;

export default AccountInfoPanel;
