import { useTranslation } from "hooks/useTranslation";
import {
  Address as IAddress,
  MailingAddressInput,
  useUser
} from "hooks/useUser";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import AddressForm from "./AddressForm";

interface Props {
  address: IAddress;
  isPrimary?: boolean;
  isEditing: boolean;
  onChangeEditing: (isEditing: boolean) => void;
}

/**
 * Address box component
 * Display an address and offers deletion and update functionalities
 */
const AddressBox: React.SFC<Props> = props => {
  const { formatMessage } = useTranslation();
  const {
    errors,
    updatingAddressesIds,
    updateAddress,
    deleteAddress
  } = useUser();

  /**
   * Process the start edit action
   */
  const handleStartEdit = () => {
    props.onChangeEditing(true);
  };

  /**
   * Process address update
   */
  const handleUpdate = async (
    address: MailingAddressInput,
    isDefaultAddress: boolean
  ) => {
    await updateAddress(props.address.id, address, isDefaultAddress);
    props.onChangeEditing(false);
  };

  /**
   * Process the cancel-edit function
   */
  const handleCancel = () => {
    props.onChangeEditing(false);
  };

  /**
   * Process address delete
   */
  const handleDelete = async () => {
    await deleteAddress(props.address.id);
  };

  /**
   * Render the address editor below the address box
   */
  const renderEditFields = () =>
    props.isEditing ? (
      <>
        {errors && errors.length > 0 ? (
          <ErrorBox>
            {formatMessage({
              id: "error.addressUpdate",
              defaultMessage: "Error updating address"
            })}
          </ErrorBox>
        ) : null}
        <AddressForm
          loading={updatingAddressesIds.includes(props.address.id)}
          address={computeMailAddressInput(props.address)}
          onCancel={handleCancel}
          onUpdate={handleUpdate}
          isPrimary={props.isPrimary}
        />
      </>
    ) : null;

  return (
    <Container>
      <Address
        primary={props.isPrimary}
        primaryString={formatMessage({
          id: "label.primary",
          defaultMessage: "Primary"
        })}
      >
        {props.address.formatted.map((elem, index) =>
          index === 0 ? (
            <Title key={index}>{elem}</Title>
          ) : (
            <div key={index}>{elem}</div>
          )
        )}
        <Actions>
          <ActionButton onClick={handleStartEdit}>
            <FormattedMessage id="button.edit" />
          </ActionButton>
          <ActionButton onClick={handleDelete}>
            <FormattedMessage id="button.delete" />
          </ActionButton>
        </Actions>
      </Address>
      {renderEditFields()}
    </Container>
  );
};

function computeMailAddressInput(address: IAddress): MailingAddressInput {
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    company: address.company,
    country: address.country,
    firstName: address.firstName,
    lastName: address.lastName,
    phone: address.phone,
    province: address.province,
    zip: address.zip
  };
}

const Container = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 760px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

interface AddressProps {
  primary?: boolean;
  primaryString?: string;
}

const Address = styled.div`
  border: 1px solid;
  border-color: ${({ primary }: AddressProps) =>
    primary ? "#977b2b" : "#c6c6c6"};
  margin: 20px;
  padding: 40px 30px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.04em;
  ${({ primary, primaryString }: AddressProps) =>
    primary
      ? `&::before {
          content: "${primaryString}";
          display: flex;
          position: absolute;
          background-color: #fff;
          border: 1px solid #977b2b;
          color: #977b2b;
          height: 24px;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
          padding: 0 20px;
          align-items: center;
        }`
      : null}
`;

const Title = styled.div`
  font-weight: 600;
  padding-bottom: 4px;
`;

const ErrorBox = styled.div`
  background-color: #ffe5e5;
  color: #dd4f4f;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
`;

const Actions = styled.div`
  margin-top: 20px;
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: 1px solid #c6c6c6;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  height: 24px;
  margin: 0 8px;
  padding-left: 8px;
  padding-right: 8px;
  transition: 0.3s all;
  &:hover {
    border: 1px solid #333;
    cursor: pointer;
  }
`;

export default AddressBox;
