import { RouteComponentProps } from "@reach/router";
import Link from "components/Link";
import { UserContext, useUser } from "hooks/useUser";
import React, { useContext } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useQuery } from "urql";
import { userQuery } from "utils/queries/user";
import { routes } from "utils/routes";

/**
 * Orders summary
 */
const Orders: React.SFC<RouteComponentProps> = () => {
  // Context
  const { user } = useUser();

  // Query
  const variables = { customerAccessToken: user!.accessToken };
  const [{ data }] = useQuery({
    query: userQuery,
    variables,
    requestPolicy: "cache-only",
  });

  if (data && data.customer) {
    return (
      <Container>
        <Title>
          <FormattedMessage id="label.orders" />
        </Title>
        <Grid>
          <Head>
            <Label>
              <FormattedMessage id="label.order" />
            </Label>
            <Label>
              <FormattedMessage id="label.date" />
            </Label>
            <Label>
              <FormattedMessage id="label.fulfillmentStatus" />
            </Label>
            <Label>
              <FormattedMessage id="label.total" />
            </Label>
          </Head>
          <Body>
            {data.customer.orders.edges.map(({ node }: any) => (
              <Order key={node.id}>
                <Item>
                  <Label>
                    <FormattedMessage id="label.order" />:{" "}
                  </Label>
                  <span> </span>
                  <Link to={`${routes.order}?id=${node.orderNumber}`}>
                    {node.name}
                  </Link>
                </Item>

                <Item>
                  <Label>
                    <FormattedMessage id="label.date" />:{" "}
                  </Label>
                  <FormattedDate value={node.processedAt} />
                </Item>

                <Item>
                  <Label>
                    <FormattedMessage id="label.fulfillmentStatus" />:{" "}
                  </Label>
                  {node.successfulFulfillments.reduce(
                    (acc: number, elem: any) =>
                      acc +
                      elem.fulfillmentLineItems.edges.reduce(
                        (acc: number, { node }: any) => acc + node.quantity,
                        0
                      ),
                    0
                  ) ==
                  node.lineItems.edges.reduce(
                    (acc: number, { node }: any) => acc + node.quantity,
                    0
                  )
                    ? "Fulfilled"
                    : "Unfulfilled"}
                </Item>

                <Item>
                  <Label>
                    <FormattedMessage id="label.total" />:{" "}
                  </Label>
                  € {node.totalPrice.amount}
                </Item>
              </Order>
            ))}
          </Body>
        </Grid>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  flex: 1;
  padding-top: 40px;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const Grid = styled.div`
  margin: 0;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.2em;
  a {
    text-decoration: underline;
    color: #333;
    &:hover {
      font-weight: 600;
    }
  }
`;

const Label = styled.div``;

const Item = styled.div``;

const Head = styled.div`
  background-color: #f8f8f8;
  color: #706f6f;
  height: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  ${Label} {
    padding: 0 15px;
    &:nth-of-type(1) {
      flex: 0 0 20%;
    }
    &:nth-of-type(2) {
      flex: 0 0 25%;
    }
    &:nth-of-type(3) {
      flex: 0 0 30%;
    }
    &:nth-of-type(4) {
      flex: 0 0 25%;
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const Body = styled.div``;

const Order = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  ${Item} {
    padding: 0 15px;
    &:nth-of-type(1) {
      flex: 0 0 20%;
    }
    &:nth-of-type(2) {
      flex: 0 0 25%;
    }
    &:nth-of-type(3) {
      flex: 0 0 30%;
    }
    &:nth-of-type(4) {
      flex: 0 0 25%;
    }
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
    height: auto;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 20px;
    ${Item} {
      height: 50px;
      display: flex;
      align-items: center;
      height: auto;

      &:nth-of-type(n) {
        flex: 0 0 100%;
        padding: 5px 15px;
      }
      &:nth-of-type(1) {
        height: 50px;
        background-color: #f8f8f8;
        color: #706f6f;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }
  ${Label} {
    display: none;
    @media (max-width: 500px) {
      display: block;
    }
  }
`;

export default Orders;
