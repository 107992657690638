import Link from "components/Link";
import { useUser } from "hooks/useUser";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useQuery } from "urql";
import { userQuery } from "utils/queries/user";
import { routes } from "utils/routes";

/**
 * Side menu component
 */
const Menu = () => {
  // Context
  const { user, deleteUser } = useUser();

  // Query
  const variables = { customerAccessToken: user!.accessToken };
  const [{ data }] = useQuery({
    query: userQuery,
    variables,
    requestPolicy: "cache-only"
  });

  if (data && data.customer) {
    return (
      <Container>
        <Background>
          <Account>
            <Title>Account</Title>
            <Name>
              {data.customer.firstName} {data.customer.lastName}
            </Name>
            <Email>{data.customer.email}</Email>
            <LogoutButton onClick={() => deleteUser()}>Logout</LogoutButton>
          </Account>
          <Navigation>
            <LinkContainer>
              <Link to={routes.account}>
                <FormattedMessage id="label.customerInformation" />
              </Link>
            </LinkContainer>
            <LinkContainer>
              <Link to={routes.orders}>
                <FormattedMessage id="label.orders" />
              </Link>
            </LinkContainer>
          </Navigation>
        </Background>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  width: 300px;
  margin-right: 60px;
  flex: 0 0 300px;
  @media (max-width: 1000px) {
    margin-right: 40px;
  }
  @media (max-width: 760px) {
    width: 100%;
    margin-right: 0;
    flex: 0 0 100%;
  }
`;

const Background = styled.div`
  background-color: #fff9e7;
  padding: 40px 30px;
  box-sizing: border-box;
`;

const Account = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
`;

const Title = styled.div`
  font-size: 18px;
  color: #977b2b;
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const Name = styled.div`
  font-size: 14px;
  color: #333;
  letter-spacing: 0.06em;
  margin-bottom: 5px;
  font-weight: 600;
`;

const Email = styled.div`
  font-size: 14px;
  color: #333;
  letter-spacing: 0.06em;
  margin-bottom: 15px;
`;

const LogoutButton = styled.div`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: right;
  font-size: 14px;
  color: #333;
  letter-spacing: 0.06em;
  &:hover {
    font-weight: 600;
  }
`;

const Navigation = styled.div`
  padding-top: 30px;
`;

const LinkContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  a {
    font-size: 14px;
    color: #333;
    letter-spacing: 0.06em;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &[aria-current="page"] {
      font-weight: 600;
    }
  }
`;

export default Menu;
