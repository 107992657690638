import React, { useState } from "react";
import Button from "components/Button";
import { useUser, MailingAddressInput } from "hooks/useUser";
import { useTranslation } from "hooks/useTranslation";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import AddressBox from "./AddressBox";
import AddressForm from "./AddressForm";

/**
 * Display a list of address boxes and offer the possibility
 * to create new addresses
 */
const AddressPanel = () => {
  const { formatMessage } = useTranslation();
  const {
    errors,
    addingAddress,
    deletingAddressesIds,
    user,
    addAddress
  } = useUser();
  const [showAddressBox, setShowAddressBox] = useState(false);
  const [editingAddressIndex, setEditingAddressIndex] = useState<number>();

  const handleAddButtonClick = () => {
    setShowAddressBox(true);
    setEditingAddressIndex(undefined);
  };

  /**
   * Handler for adding an address for the user
   */
  const handleAdd = async (
    address: MailingAddressInput,
    isDefaultAddress: boolean
  ) => {
    await addAddress(address, isDefaultAddress);
    setShowAddressBox(false);
  };

  /**
   * Render add address form and error fields
   */
  const renderAddAddress = () =>
    showAddressBox ? (
      <>
        {errors && errors.length > 0 ? (
          <ErrorBox>
            {formatMessage({
              id: "error.addressAdd",
              defaultMessage: "Error adding address"
            })}
          </ErrorBox>
        ) : null}
        <AddressForm
          loading={addingAddress}
          onUpdate={handleAdd}
          onCancel={() => setShowAddressBox(false)}
        />
      </>
    ) : null;

  if (user) {
    return (
      <Container>
        <Header>
          <Title>
            <FormattedMessage id="label.addresses" />
          </Title>
          <Button size="slim" onClick={handleAddButtonClick}>
            <FormattedMessage id="label.addAddress" />
          </Button>
        </Header>
        {renderAddAddress()}
        <AddressList>
          {user.addresses
            .filter(address => !deletingAddressesIds.includes(address.id))
            .map((address, index) => (
              <AddressBox
                key={address.id}
                address={address}
                isPrimary={address.id === user.defaultAddressId}
                isEditing={!showAddressBox && index === editingAddressIndex}
                onChangeEditing={isEditing => {
                  setShowAddressBox(prev => (isEditing ? false : prev));
                  setEditingAddressIndex(isEditing ? index : undefined);
                }}
              />
            ))}
        </AddressList>
      </Container>
    );
  }
  return null;
};

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  color: #977b2b;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 600;
  padding-right: 20px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const AddressList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: 20px;
`;

const ErrorBox = styled.div`
  background-color: #ffe5e5;
  color: #dd4f4f;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
`;

export default AddressPanel;
