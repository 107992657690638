import { RouteComponentProps } from "@reach/router";
import { useUser } from "hooks/useUser";
import React from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import { userQuery } from "utils/queries/user";
import AccountInfoPanel from "./AccountInfoPanel";
import AddressPanel from "./AddressesPanel";

/**
 * Account page
 * Entry point of the user authenticated area
 */
const Account: React.SFC<RouteComponentProps> = () => {
  // Context
  const { user } = useUser();

  // Query
  const variables = { customerAccessToken: user!.accessToken };
  const [{ data }] = useQuery({
    query: userQuery,
    variables,
    requestPolicy: "cache-only"
  });

  if (data && data.customer) {
    return (
      <Container>
        <AccountInfoPanel />
        <AddressPanel />
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  flex: 1;
  padding-top: 40px;
`;

export default Account;
