import { RouteComponentProps } from "@reach/router";
import { UserContext, useUser } from "hooks/useUser";
import qs from "querystring";
import React, { useContext, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import styled from "styled-components";
import { useQuery } from "urql";
import { userQuery } from "utils/queries/user";
import { graphql, useStaticQuery } from "gatsby";

interface OrderProps {
  locale: string;
  defaultLocale: string;
}

/**
 * Order detail component
 */
const Order: React.SFC<RouteComponentProps & OrderProps> = ({
  locale,
  defaultLocale,
}) => {
  // Static data
  const staticQueryResult = useStaticQuery(staticQuery);
  const productOptions = staticQueryResult.allDatoCmsProductOption.nodes;
  const productTitles = staticQueryResult.allDatoCmsProduct.nodes;

  // Context
  const { user } = useUser();

  // State variables
  const [order, setOrder] = useState<any>(undefined);

  // Query
  const variables = { customerAccessToken: user!.accessToken };
  const [{ data }] = useQuery({
    query: userQuery,
    variables,
    requestPolicy: "cache-only",
  });

  // Effects
  useEffect(() => {
    const customer = data && data.customer;
    const location = window.location;
    const queryString = location && location.search.replace("?", "");
    const queryParams = qs.parse(queryString);
    if (customer && queryParams && queryParams.id) {
      setOrder(
        customer.orders.edges.find(
          ({ node }: any) => node.orderNumber == queryParams.id
        )
      );
    }
  }, [data]);

  const retrieveProductTitle = (title: string, locale: string) => {
    const titleLocale = productTitles.find(({ _allTitleLocales }: any) => {
      const defaultTitleLocale = _allTitleLocales.find(
        (titleLocale: any) => titleLocale.locale === defaultLocale
      );
      return defaultTitleLocale.value === title;
    });
    const localizedTitle =
      titleLocale &&
      titleLocale._allTitleLocales.find(
        (title: any) => title.locale === locale
      );

    return (localizedTitle && localizedTitle.value) || title;
  };

  const retrieveProductVariant = (
    variantName: string,
    variantValue: string,
    locale: string
  ) => {
    const option = productOptions.find(
      (option: any) => option.shopifyOptionName === variantName
    );
    const optionLocale =
      option &&
      option._allOptionNameLocales.find(
        (valueLocale: any) => valueLocale.locale === locale
      );
    const optionValue =
      option &&
      option.values.find((value: any) => value.shopifyValue === variantValue);
    const optionValueLocale =
      optionValue &&
      optionValue._allValueLocales.find(
        (valueLocale: any) => valueLocale.locale === locale
      );
    return `${(optionLocale && optionLocale.value) || variantName}: ${
      (optionValueLocale && optionValueLocale.value) || variantValue
    }`;
  };

  if (order) {
    return (
      <Container>
        <Title>
          <FormattedMessage id="label.order" /> {order.node.name}
        </Title>
        <Subtitle>
          <FormattedMessage id="label.placedOn" />{" "}
          <FormattedDate
            day="2-digit"
            month="long"
            year="numeric"
            value={order.node.processedAt}
          />
          {", "}
          <FormattedTime
            hour="2-digit"
            minute="2-digit"
            hour12={true}
            value={order.node.processedAt}
          />
        </Subtitle>
        <Grid>
          <Head>
            <FormattedMessage id="label.items" />
          </Head>
          <Body>
            {order.node.lineItems.edges.map(({ node }: any, index: number) => (
              <Product key={index}>
                <Item>
                  <ProductTitle>
                    {retrieveProductTitle(node.title, locale)}
                  </ProductTitle>
                  {node.variant &&
                    node.variant.selectedOptions.map(
                      (option: any, index: number) => (
                        <Variant key={index}>
                          {retrieveProductVariant(
                            option.name,
                            option.value,
                            locale
                          )}
                        </Variant>
                      )
                    )}
                  {node.customAttributes.map((attribute: any, index: number) =>
                    attribute.value ? (
                      <Property key={index}>
                        {attribute.key}: {attribute.value}
                      </Property>
                    ) : (
                      ""
                    )
                  )}
                </Item>
                {node.variant && node.variant.sku && (
                  <Item doublePadding>
                    <FormattedMessage id="label.sku" />: {node.variant.sku}
                  </Item>
                )}
                {node.variant && node.variant.price && (
                  <Item alignRight>
                    <FormattedMessage id="label.price" />: €{" "}
                    {node.variant.price.amount}
                  </Item>
                )}
                <Item alignRight>
                  <FormattedMessage id="label.qty" />: {node.quantity}
                </Item>
                {node.variant && node.variant.price && (
                  <Item alignRight>
                    <DiscountablePrice>
                      <FormattedMessage id="label.total" />: €{" "}
                      {(
                        node.variant &&
                        node.variant.price.amount * node.quantity
                      ).toFixed(2)}
                    </DiscountablePrice>
                    {node.variant &&
                      node.discountAllocations &&
                      node.discountAllocations.length > 0 && (
                        <ItemPrice>
                          €{" "}
                          {(
                            node.variant.price.amount -
                            node.discountAllocations.reduce(
                              (acc: number, elem: any) =>
                                acc + elem.allocatedAmount.amount,
                              0
                            )
                          ).toFixed(2)}
                        </ItemPrice>
                      )}
                  </Item>
                )}
              </Product>
            ))}
            <Total>
              <Item alignRight>
                <FormattedMessage id="label.subtotal" />: €{" "}
                {order.node.subtotalPrice.amount}
              </Item>
              <Item alignRight>
                <FormattedMessage id="label.discount" />: €{" "}
                {Number(
                  order.node.lineItems.edges.reduce(
                    (acc: number, { node }: any) => {
                      if (
                        acc + node.discountAllocations &&
                        node.discountAllocations.length > 0
                      ) {
                        return (
                          acc +
                          node.discountAllocations.reduce(
                            (acc: number, elem: any) =>
                              acc + elem.allocatedAmount.amount,
                            0
                          )
                        );
                      }
                      return acc;
                    },
                    0
                  )
                ).toFixed(2)}
              </Item>
              <Item alignRight>
                <FormattedMessage id="label.shipping" />: €{" "}
                {order.node.totalShippingPrice.amount}
              </Item>
              <Item alignRight big>
                <FormattedMessage id="label.total" />: €{" "}
                {order.node.totalPrice.amount}
              </Item>
            </Total>
          </Body>
        </Grid>
        <Grid>
          <Head>
            <FormattedMessage id="label.fulfillmentStatus" />
          </Head>
          <ItemsContainer>
            <Item>
              {order.node.successfulFulfillments.reduce(
                (acc: number, elem: any) =>
                  acc +
                  elem.fulfillmentLineItems.edges.reduce(
                    (acc: number, { node }: any) => acc + node.quantity,
                    0
                  ),
                0
              ) ==
              order.node.lineItems.edges.reduce(
                (acc: number, { node }: any) => acc + node.quantity,
                0
              ) ? (
                <FormattedMessage id="label.fulfilled" />
              ) : (
                <FormattedMessage id="label.unfulfilled" />
              )}
            </Item>
          </ItemsContainer>
        </Grid>
        <Grid>
          <Head>
            <FormattedMessage id="label.shippingAddress" />
          </Head>
          <ItemsContainer>
            <Address>
              {order.node.shippingAddress.formatted.map(
                (elem: any, index: number) => (
                  <div key={index}>{elem}</div>
                )
              )}
            </Address>
          </ItemsContainer>
        </Grid>
      </Container>
    );
  }
  return null;
};

const staticQuery = graphql`
  {
    allDatoCmsProductOption(filter: { locale: { eq: "it" } }) {
      nodes {
        shopifyOptionName
        selectorType
        _allOptionNameLocales {
          locale
          value
        }
        values {
          shopifyValue
          _allValueLocales {
            locale
            value
          }
        }
      }
    }
    allDatoCmsProduct(filter: { locale: { eq: "it" } }) {
      nodes {
        _allTitleLocales {
          locale
          value
        }
      }
    }
  }
`;

const Container = styled.div`
  flex: 1;
  padding-top: 40px;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const Subtitle = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 25px;
  font-weight: 600;
`;

const Grid = styled.div`
  margin: 0;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.2em;
  a {
    text-decoration: underline;
    color: #333;
    &:hover {
      font-weight: 600;
    }
  }
`;

interface ItemProps {
  alignRight?: boolean;
  big?: boolean;
  doublePadding?: boolean;
}

const Item = styled.div`
  text-align: ${({ alignRight }: ItemProps) => (alignRight ? "right" : "left")};
  padding-bottom: ${({ doublePadding }: ItemProps) =>
    doublePadding ? "20px" : "10px"};
  box-sizing: border-box;
  font-size: ${({ big }: ItemProps) => (big ? "18px" : "14px")};
  font-weight: ${({ big }: ItemProps) => (big ? "600" : "400")};
  &:last-of-type {
    padding-bottom: 0;
  }
`;

const DiscountablePrice = styled.span`
  font-weight: 600;
  &:not(:last-child) {
    font-weight: 400;
    text-decoration: line-through;
  }
`;

const ItemPrice = styled.span`
  font-weight: 600;
`;

const Head = styled.div`
  background-color: #f8f8f8;
  color: #706f6f;
  height: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 0 15px;
  box-sizing: border-box;
`;

const Body = styled.div`
  margin-bottom: 40px;
`;

const Product = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  padding: 20px 15px;
`;

const ProductTitle = styled.div`
  padding-bottom: 5px;
`;

const Variant = styled.div`
  color: #706f6f;
  padding-top: 4px;
`;

const Property = styled.div`
  color: #706f6f;
  padding-top: 4px;
`;

const Total = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  padding: 20px 15px;
`;

const ItemsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  padding: 20px 15px;
  margin-bottom: 40px;
`;

const Address = styled.div`
  line-height: 1.6em;
  > div:first-of-type {
    font-weight: 600;
  }
`;

export default Order;
