import Button from "components/Button";
import InputText from "components/InputText";
import Select from "components/Select";
import { useTranslation } from "hooks/useTranslation";
import { MailingAddressInput } from "hooks/useUser";
import React, { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import countries from "utils/data/country.json";

interface Props {
  address?: MailingAddressInput;
  isPrimary?: boolean;
  loading: boolean;
  onUpdate: (address: MailingAddressInput, setDefaultAddress: boolean) => void;
  onCancel: () => void;
}

interface Province {
  code: string;
  name: string;
}

/**
 * Simple address form component
 * Receive callback props for update and close
 */
const AddressForm: React.SFC<Props> = props => {
  const { formatMessage } = useTranslation();

  const [address, setAddress] = useState<MailingAddressInput>(
    props.address || { country: countries[0].code }
  );
  const [isDefaultAddress, setDefaultAddress] = useState(
    props.isPrimary || false
  );
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);

  const _extractProvinces = (country: string): Province[] => {
    const foundCountry = countries.find(
      c => c.code === country || c.name === country
    );
    return foundCountry ? foundCountry.provinces : [];
  };

  // Memoized methods
  const provinces = useMemo(
    () => (address.country ? _extractProvinces(address.country) : []),
    [address.country]
  );

  const checkAddressValidity = (address: MailingAddressInput): boolean => {
    const fieldsWithError = [];
    if (!address.company) {
      if (!address.firstName || !address.lastName) {
        fieldsWithError.push("firstName", "lastName", "company");
      }
    }
    if (!address.address1) {
      fieldsWithError.push("address1");
    }
    if (!address.city) {
      fieldsWithError.push("city");
    }
    if (!address.country) {
      fieldsWithError.push("country");
    }
    if (!address.phone) {
      fieldsWithError.push("phone");
    }
    if (fieldsWithError.length > 0) {
      setFieldsWithError(fieldsWithError);
      return false;
    }
    setFieldsWithError([]);
    return true;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (checkAddressValidity(address)) {
      props.onUpdate(address, isDefaultAddress);
    }
  };

  return (
    <Form>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.firstName",
            defaultMessage: "First name"
          })}
          hasError={fieldsWithError.includes("firstName")}
          value={address.firstName || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, firstName: e.target.value })
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.lastName",
            defaultMessage: "Last name"
          })}
          hasError={fieldsWithError.includes("lastName")}
          value={address.lastName || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, lastName: e.target.value })
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.company",
            defaultMessage: "Company"
          })}
          hasError={fieldsWithError.includes("company")}
          value={address.company || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, company: e.target.value })
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.address1",
            defaultMessage: "Address 1"
          })}
          hasError={fieldsWithError.includes("address1")}
          value={address.address1 || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, address1: e.target.value })
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.address2",
            defaultMessage: "Address 2"
          })}
          hasError={fieldsWithError.includes("address2")}
          value={address.address2 || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, address2: e.target.value })
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.city",
            defaultMessage: "Ciry"
          })}
          hasError={fieldsWithError.includes("city")}
          value={address.city || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, city: e.target.value })
          }
        />
      </div>
      <div>
        <Select
          size="small"
          placeholder={formatMessage({
            id: "label.country",
            defaultMessage: "Country"
          })}
          hasError={fieldsWithError.includes("country")}
          value={address.country}
          useSystemSelect={true}
          options={countries}
          display={value => value.name}
          valueChange={value =>
            setAddress({
              ...address,
              country: value.code,
              province:
                value.provinces &&
                value.provinces.length > 0 &&
                value.provinces[0].code
            })
          }
          compare={(value, option) =>
            value === option.code || value === option.name
          }
        />
      </div>
      <div>
        <Select
          size="small"
          placeholder={formatMessage({
            id: "label.province",
            defaultMessage: "State / Province"
          })}
          value={address.province}
          hasError={fieldsWithError.includes("province")}
          disabled={provinces.length === 0}
          options={provinces}
          useSystemSelect={true}
          display={value => value.name}
          valueChange={value =>
            setAddress({ ...address, province: value.code })
          }
          compare={(value, option) =>
            value === option.code || value === option.name
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.zip",
            defaultMessage: "Postal / Zip Code"
          })}
          hasError={fieldsWithError.includes("zip")}
          value={address.zip || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, zip: e.target.value })
          }
        />
      </div>
      <div>
        <InputText
          size="small"
          type="text"
          placeholder={formatMessage({
            id: "label.phone",
            defaultMessage: "Phone"
          })}
          hasError={fieldsWithError.includes("phone")}
          value={address.phone || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, phone: e.target.value })
          }
        />
      </div>
      {props.isPrimary ? null : (
        <Checkbox>
          <input
            type="checkbox"
            checked={isDefaultAddress}
            onChange={() => setDefaultAddress(!isDefaultAddress)}
          />
          <label>
            <span>
              <FormattedMessage id="label.setAsDefault" />
            </span>
          </label>
        </Checkbox>
      )}
      <ActionContainer>
        <Button size="slim" loading={props.loading} onClick={handleSubmit}>
          {props.address
            ? formatMessage({ id: "label.update" })
            : formatMessage({ id: "label.add" })}
        </Button>
        <LinkButton
          onClick={(e: any) => {
            e.preventDefault();
            props.onCancel();
          }}
        >
          <FormattedMessage id="label.cancel" />
        </LinkButton>
      </ActionContainer>
    </Form>
  );
};

const Form = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 20px;
  box-sizing: border-box;
`;

const Checkbox = styled.div`
  position: relative;
  height: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    position: absolute;
    top: 0;
    height: 20px;
    align-items: center;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
    }
    span {
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 12px;
      letter-spacing: 0.04em;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LinkButton = styled.button`
  background-color: none;
  border: none;
  font-family: soleil, sans-serif;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.04em;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default AddressForm;
