import { navigate, Router } from "@reach/router";
import Account from "components/account/Account";
import Menu from "components/account/Menu";
import Order from "components/account/Order";
import Orders from "components/account/Orders";
import Wrapper from "components/Wrapper";
import { UserContext, useUser } from "hooks/useUser";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import { useLocale } from "hooks/useLocale";
import { userQuery } from "utils/queries/user";
import { routes } from "utils/routes";
import { FieldLocale } from "utils/common/locale";
import SEO from "components/SEO";
import { useTranslation } from "hooks/useTranslation";

interface Props {
  pageContext: {
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

/**
 * Authenticated section wrapper
 * Just to allow useContext usage inside
 */
const AccountRoot: React.SFC<Props> = ({ pageContext }) => {
  const { pagePath } = pageContext;
  const { locale, defaultLocale } = useLocale();

  return locale ? (
    <AuthenticationTokenGuard
      locale={locale}
      defaultLocale={defaultLocale}
      pagePath={pagePath}
    />
  ) : null;
};

/**
 * Guard looking for the user authentication token
 */
const AuthenticationTokenGuard: React.SFC<AuthenticatedRoutesProps> = props => {
  // Context
  const { loading, user } = useUser();
  const { formatMessage } = useTranslation();

  // Effects
  useEffect(() => {
    if (!loading && !user) {
      navigate(routes.login);
    }
  }, [loading, user]);

  if (user) {
    return (
      <>
        <SEO
          title={formatMessage({ id: "SEO.accountTitle" })}
          link={[
            {
              rel: "canonical",
              href: "https://ruedesmille.com" + props.pagePath
            }
          ]}
        />
        <AuthenticatedRoutes {...props} />
      </>
    );
  }
  return null;
};

interface AuthenticatedRoutesProps {
  locale: string;
  defaultLocale: string;
  pagePath: string;
}

/**
 * Authenticated routes are rendered only if the corresponding user
 * has been correctly fetched. As a consequence al sub-components
 * can fetch user data from cache only
 */
const AuthenticatedRoutes: React.SFC<AuthenticatedRoutesProps> = ({
  locale,
  defaultLocale
}) => {
  // Context
  const { user, deleteUser } = useUser();

  // Query
  const variables = { customerAccessToken: user!.accessToken };
  const [{ fetching, data, error }] = useQuery({ query: userQuery, variables });

  // Effects
  useEffect(() => {
    if (!fetching && ((data && !data.customer) || error)) {
      deleteUser();
      navigate(routes.login);
    }
  }, [fetching, data, error]);

  if (data && data.customer) {
    return (
      <Container>
        <Wrapper size="small">
          <Menu />
          <Content>
            <Router>
              <Account path={routes.account} />
              <Orders path={routes.orders} />
              <Order
                path={routes.order}
                locale={locale}
                defaultLocale={defaultLocale}
              />
            </Router>
          </Content>
        </Wrapper>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  ${Wrapper} {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    @media (max-width: 760px) {
      flex-direction: column;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
`;

export default AccountRoot;
